export default {
  'VideoWidget': () => import('./Video.vue'),
  'ZoomImage': () => import('./ZoomImage.vue'),
  'GalleryMedium': () => import('./GalleryMedium.vue'),
  'GallerySmall': () => import('./GallerySmall.vue'),
  'ModalDefault': () => import('./modals/ModalDefault'),
  'ModalSuccess': () => import('./modals/ModalSuccess'),
  'ButtonModal': () => import('./buttons/ButtonModal'),
  'CartItemsCount': () => import('./data/CartItemsCount'),
  'ButtonCartAddItem': () => import('./buttons/ButtonCartAddItem'),
  'Offer': () => import('./Form/Offer'),
  'MapHouses': () => import('./MapHouses'),
}
