
import Vue from 'vue'
import store from './store'
import axios from 'axios'
import VueCookies from 'vue-cookies';

import modules from './modules/module';
import widgets from './widgets/widget';
import workers from './workers/worker';

Vue.prototype.$axios = axios.create({
  baseURL: 'https://vevanta.ru/api/',
})

Vue.config.productionTip = false
Vue.use(VueCookies);

let components = {
  'ComplectPrices': () => import('./components/ComplectPrices'),
  'MultiForm': () => import('./components/form/MultiForm'),
  'InputName': () => import('./components/form/input/Name'),
  'InputText': () => import('./components/form/input/text'),
  'InputFamily': () => import('./components/form/input/Family'),
  'InputPhone': () => import('./components/form/input/Phone'),
  'InputEmail': () => import('./components/form/input/Email'),
  'InputFile': () => import('./components/form/input/File'),
  'preloader': () => import('./components/Preloader'),
  'Boot': () => import('./components/form/Boot'),
  'clients': () => import('./components/clients/index'),
  'NewBunner': () => import('./components/NewBunner'),
  'SlotFixed': () => import('./components/SlotFixed'),
  'Ipoteka': () => import('./components/Ipoteka'),
  'ManagerItem': () => import('./components/ManagerItem'),
  'SearchFilterHome': () => import('./components/SearchFilterHome'),
  'Consultation': () => import('./components/Consultation'),
  'SlotModal': () => import('./components/SlotModal'),
  'FormPhone': () => import('./components/FormPhone'),
  'FormTwo': () => import('./components/FormTwo'),
  'FormPhone': () => import('./components/FormPhone'),
  'IpotekaCalc': () => import('./components/IpotekaCalc'),
  'Quiz1': () => import('./components/Quiz1'),
  'VideoDesctop': () => import('./components/VideoDesctop'),
  'NavBottom': () => import('./components/NavBottom'),
  'TextOverflow': () => import('./components/TextOverflow'),
  'textOverflows': () => import('./components/TextOverflows'),
  'PhotoReport': () => import('./components/PhotoReport'),
  'MultiQuiz': () => import('./components/MultiQuiz'),
  'Visit': () => import('./components/Visit'),
  'TargetGoal': () => import('./components/TargetGoal'),
  'Calc': () => import('./modules/Calc/Calc'),
  ...modules,
  ...widgets,
  ...workers
}


let vm = '';
let render = () => {

   vm = new Vue({
    store,
    el: '#app',
    components,
  }).$mount()
}


render()



  let wc = Object.keys(components).map(item => {
    return item.toUpperCase();
  })


    let observer = new MutationObserver((records) => {

      records.forEach(item => {
        if (item.addedNodes.length) {
          item.addedNodes.forEach((item, i) => {

          if (!item.tagName) return
          let tagName = item.tagName.replace('-', '').toUpperCase();

         if (wc.includes(tagName) || item.hasAttribute('builder')) {
           item.removeAttribute('builder')
             let div = document.createElement('div')
             item.replaceWith(div)
             div.appendChild(item)

              new Vue({
               store,
               el: div,
               components,
             }).$mount()

           }
          });

        }
      })
    });


    observer.observe(document.getElementById('app'), {
      childList: true, // наблюдать за непосредственными детьми
      subtree: true, // и более глубокими потомками
      characterDataOldValue: true // передавать старое значение в колбэк
    });
